import React, { useRef } from 'react';
import { View, StyleSheet, FlatList, TouchableHighlight, Platform, Pressable } from 'react-native';
import InterReg from './InterReg';
import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu';
import { Feather } from '@expo/vector-icons';
import CustomMenu from '../utils/customMenu';
import { SECONDARY_COLOR, GRAY_LINE_COLOR, GRAY_LIGHT  } from '../constants';

const OPTION_HEIGHT = 52;

const Option = ({onSelect, index, label, value, multiselect, closeMenu, selected, isLast, optionAction, optionIcon }) => { 
    const isSelected = selected.includes(value);
    const handleActionPress = () => {
        closeMenu(); 
        optionAction();
    }
    return (
        <TouchableHighlight 
            underlayColor={ GRAY_LIGHT }
            activeOpacity={1}
            style={[
                styles.option, 
                isLast && { borderBottomWidth: 0 }
            ]}
            onPress={() => {
                onSelect(index);
                !multiselect && closeMenu();
            }}>
            <>
            <View style={[styles.checkbox, { backgroundColor: isSelected ? SECONDARY_COLOR : 'transparent', borderColor: SECONDARY_COLOR }]}>
                { isSelected && <Feather name={'check'} size={17} color={'#fff'} /> }
            </View>
            <InterReg style={{ marginLeft: 10, flex: 1 }} text={label} />
            { optionAction && 
                <Pressable 
                    style={styles.optionActionBtn} 
                    hitSlop={{ top: 10, right: 8, bottom: 10, left: 8 }} 
                    onPress={handleActionPress}>
                    { optionIcon }
                </Pressable>
            }
            </>
        </TouchableHighlight>
    )
}

export default function ContextMenu({ TriggerButton, style, onSelect, menuWidth, multiselect, selected, placement='auto', onClose, onOpen, options }) {
    const optionsContainerStyles = { 
        borderRadius: 8, 
        ...Platform.select({
            android: {
                elevation: 5
            },
            ios: {
                shadowColor: '#000', 
                shadowOpacity: '0.3', 
                shadowOffset: { width: 0, height: 0 }
            },
            default: {
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px'
            }
          })
        
    }
    const menuRef = useRef(null);
    const flatListRef = useRef(null);
    function closeMenu() {
        menuRef.current.close();
    }
    function scrollToItem(flatListHeight) {
        if(flatListHeight) {
            const firstSelectedValue = typeof selected == 'array' ? selected[0] : selected;
            const firstSelectedValueIndex = options.findIndex(({value}) => value == firstSelectedValue);
            const yOfCurrentlySelectedOption = OPTION_HEIGHT * firstSelectedValueIndex + OPTION_HEIGHT;
            if(flatListRef.current && firstSelectedValueIndex > -1 && yOfCurrentlySelectedOption > flatListHeight) {
                flatListRef.current.scrollToIndex({ animated: false, index: firstSelectedValueIndex });
            }
        }
    }
    return (
        <Menu 
            onClose={ () => onClose && onClose() }
            onOpen={ () => onOpen && onOpen() }
            style={ style }
            ref={ menuRef }
            renderer={ CustomMenu } 
            rendererProps={{
                placement,
                preferredPlacement: 'bottom', 
                anchorStyle: { zIndex: 2 } 
            }}>
            <MenuTrigger customStyles={{ 
                    triggerTouchable: { 
                        hitSlop: { top: 10, left: 10, right: 10, bottom: 10 },
                        underlayColor: 'transparent'
                    }
                }}>
                { TriggerButton() }
            </MenuTrigger>
            <MenuOptions 
                customStyles={{ 
                    optionsContainer: {width: menuWidth, magin: 0, paddingVertical: 0, ...optionsContainerStyles }, 
                }}>
                <FlatList
                    style={{ maxHeight: OPTION_HEIGHT*3.6, borderRadius: 8 }} 
                    data={ options } 
                    ref={flatListRef}
                    getItemLayout={(data, index) => (
                        {length: OPTION_HEIGHT, offset: OPTION_HEIGHT * index, index}
                    )}
                    onLayout={({nativeEvent}) => scrollToItem(nativeEvent.layout.height) }
                    keyExtractor={(i) => i.label}
                    renderItem={ ({item, index }) => (
                        <Option 
                            {...item} 
                            multiselect={multiselect} 
                            selected={selected} 
                            closeMenu={closeMenu} 
                            onSelect={onSelect} 
                            index={index}
                            isLast={index == options.length - 1} />
                    )} />
            </MenuOptions>
        </Menu>
    )
}

const styles = StyleSheet.create({
    option: {
        borderBottomWidth: StyleSheet.hairlineWidth, 
        borderBottomColor: GRAY_LINE_COLOR, 
        flexDirection: 'row', 
        alignItems: 'center',
        padding: 15,
        height: OPTION_HEIGHT
    },
    checkbox: {
        width: 22, 
        height: 22, 
        borderWidth: 2, 
        borderRadius: 11,
        alignItems: 'center',
        justifyContent: 'center',
    },
    optionActionBtn: { 
        height: 22, 
        width: 22, 
        marginLeft: 'auto', 
        alignItems: 'center', 
        justifyContent: 'center' 
    }
})